import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="site-container not-found-page">
      <div className="container text-center">
        <h1>{data.info.title404}</h1>
        <p>{data.info.subtitle404}</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query Error404 {
    info: contentfulSiteInformation {
      title404
      subtitle404
    }
  }
`;
